import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";
import { SiRazorpay } from "react-icons/si";
// SiPaytm
import Paytm from "assets/img/pytm.png"

const useStyles = makeStyles(styles);

export default function InshaFooter() {
  const classes = useStyles();
  // const activeSession = sessionStorage.getItem("sessionData");
  return (
    <Footer
      theme="dark"
      content={
        <div>
          <div className={classes.left}>
            <a
              href="/product-list"
              className={classes.footerBrand}
            >
              Indian Bangles
            </a>
          </div>
          <div className={classes.pullCenter}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/making-charge"
                  className={classes.block}
                >
                  Making Charge
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/insha-bangles-size-guide"
                  className={classes.block}
                >
                  Bangles Size Chart
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/about-us"
                  className={classes.block}
                >
                  About us
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/privacyPolicy"
                  // target="_blank"
                  className={classes.block}
                >
                  Privacy Policy
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/terms-and-conditions"
                  // target="_blank"
                  className={classes.block}
                >
                  Terms & Condition
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/return-policy"
                  // target="_blank"
                  className={classes.block}
                >
                  Return Policy
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/refund-policy"
                  // target="_blank"
                  className={classes.block}
                >
                  Refund Policy
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/license"
                  // target="_blank"
                  className={classes.block}
                >
                  license
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/careers"
                  // target="_blank"
                  className={classes.block}
                >
                  Careers
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/todays-deal"
                  className={classes.block}
                >
                  Todays Deal
                </a>
              </ListItem>
              {/* <ListItem className={classes.inlineBlock}>
                <a
                  href="/offline-product-list"
                  className={classes.block}
                >
                  Offline Product
                </a>
              </ListItem> */}
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/image-gallery"
                  className={classes.block}
                >
                  Image Gallery
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/contact-us"
                  className={classes.block}
                >
                  Contact Us
                </a>
              </ListItem>
              {/* {activeSession ? 
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/payment"
                  className={classes.block}
                >
                  Payment
                </a>
              </ListItem> : "" } */}
            </List>
          </div>
          <div className={classes.pullCenter}>
            <a
              href="#"
              className={classes.aClasses}
            >
              <SiRazorpay size={25} color="#e91e63" />Razorpay
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="#"
              className={classes.aClasses}
              style={{ backgroundColor: "white" }}
            >
              <img src={Paytm} />
            </a>
          </div>
          <div className={classes.right}>
            &copy; {1900 + new Date().getYear()}{" "}
            <a
              className={classes.aClasses}
            >
              indianbangles.in
            </a>
          </div>
        </div>
      }
    />
  );
}